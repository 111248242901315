import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { keyBy } from 'lodash'

import { tagQueryKeys, tagService } from 'lib/services/tagService'
import { Tag } from 'lib/models/tag'
import { getErrorFromQuery } from '../utils/getErrorFromQuery'
import { Account } from 'lib/models/account'
import { accountService } from 'lib/services/accountService'
import { Organization } from 'lib/models/organization'

export type Metadata = {
    tagsById: Record<number, Tag>
    account: Account
    organizations: Organization[]
}

export const InitMetadata: Metadata = {
    tagsById: {},
    organizations: [],
    account: {
        account_id: 0,
        portal_id: '',
        company: null,
        email: null,
        tel: null,
        tz: null,
        invoice_generation_type: 'month_of',
        stripe_customer: null,
        stripe_subscription: null,
        stripe_customer_id: null,
        stripe_customer_subscription_id: null,
        period_end: null,
        plan_id: null,
        plan_name: null,
        features: null,
        syncfy_id_user: null,
        current_state: 'active',
        cfdi_use: null,
        cfdi_payment_form: null,
        cfdi_payment_method: null,
        cfdi_round_unit_price: false,
        cfdi_display_due_date_info: null,
        cfdi_display_sku: false,
        created_at: new Date(),
        updated_at: new Date(),
    },
}

const fiveMinutes = 1000 * 60 * 5 // 5 minutes
const twoMinutes = 1000 * 60 * 5 // 2 minutes

export const useMetadata = (
    hasSession: boolean
): {
    isLoading: boolean
    metadata: Metadata
    error: string | undefined
} => {
    const tagsQuery = useQuery({
        queryKey: tagQueryKeys.list,
        queryFn: () => tagService.getTags(),
        staleTime: fiveMinutes,
        enabled: hasSession,
    })

    const accountQuery = useQuery({
        queryKey: ['account'],
        queryFn: () => accountService.get(),
        staleTime: twoMinutes,
        enabled: hasSession,
    })

    const tagsError = getErrorFromQuery(tagsQuery.error, tagsQuery.data)
    const accountsError = getErrorFromQuery(accountQuery.error, accountQuery.data)

    const error = tagsError || accountsError
    const isLoading = tagsQuery.isLoading || accountQuery.isLoading

    const tagsById: Record<number, Tag> = useMemo(
        () => keyBy(tagsQuery.data?.tags ?? [], 'tag_id'),
        [tagsQuery.data]
    )

    const account = useMemo(
        () => accountQuery.data?.account || InitMetadata.account,
        [accountQuery.data]
    )

    const organizations = useMemo(
        () => accountQuery.data?.organizations || [],
        [accountQuery.data]
    )

    const metadata: Metadata = useMemo(
        () => ({ tagsById, account, organizations }),
        [tagsById, account, organizations]
    )

    return { isLoading, metadata, error }
}
